import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import SecondaryHero from "../../components/secondary-hero";
import EventSection from "../../components/event-section";
import VolunteerCard from "../../components/volunteer-card";

const VolunteeringStoriesPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			volunteers: allWpVolunteer {
				nodes {
					slug
					volunteerFields {
						description
						name
						volunteerWork
						cardImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Volunteering",
				item: {
					url: `${siteUrl}/volunteering`,
					id: `${siteUrl}/volunteering`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Volunteering Stories",
				item: {
					url: `${siteUrl}/volunteering/volunteering-stories`,
					id: `${siteUrl}/volunteering/volunteering-stories`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Volunteering Stories | Friends of Eastbourne Hospital"
				description="Our valued volunteers are many, and each comes to their role with different motivations and skills. Here’s a bit about them."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/volunteering/volunteering-stories`,
					title: "Volunteering Stories | Friends of Eastbourne Hospital",
					description:
						"Our valued volunteers are many, and each comes to their role with different motivations and skills. Here’s a bit about them.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Volunteering stories"
						description={
							<span>
								Our valued volunteers are many, and each comes to their role
								<br className="d-none d-md-block" />
								with different motivations and skills. Here’s a bit about them.
							</span>
						}
					/>
				</section>
				<section className="pb-md-7 pb-5">
					<Container>
						<Row className="justify-content-center pt-lg-5">
							{data.volunteers.nodes.map((volunteer) => (
								<VolunteerCard volunteer={volunteer} />
							))}
						</Row>
					</Container>
				</section>
				<section>
					<EventSection />
				</section>
			</Layout>
		</>
	);
};

export default VolunteeringStoriesPage;
