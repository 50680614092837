import React from "react";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const VolunteerCard = ({ volunteer }) => {
	return (
		<Col style={{ overflow: "hidden" }} className="mb-5 pb-xl-0" md={6} lg={4}>
			<div
				style={{ overflow: "hidden" }}
				className=" w-100 h-100 position-relative magazine-issue-container"
			>
				<GatsbyImage
					style={{ zIndex: -1 }}
					class="w-100 "
					image={
						volunteer.volunteerFields.cardImage?.localFile.childImageSharp
							.gatsbyImageData
					}
					alt={volunteer.volunteerFields.name}
				/>

				<div style={{ zIndex: 1 }} className="bg-light-blue p-4">
					<h2 className=" text-primary  fs-3  ">
						{volunteer.volunteerFields.name}
					</h2>
					<p className="inter-bold">
						{volunteer.volunteerFields.volunteerWork}
					</p>
					<p>"{volunteer.volunteerFields.description}"</p>
					<Link
						className="red-link py-4"
						to={`/volunteering/volunteering-stories/${volunteer.slug}`}
					>
						Read More
					</Link>
				</div>
			</div>
		</Col>
	);
};

export default VolunteerCard;
